
import { Options, Vue } from "vue-class-component";

var cacheEvent: any = null;
@Options({
  props: {},
  data() {
    return {
      keyObj: {
        altKey: "",
        ctrlKey: "",
        metaKey: "",
        shiftKey: "",

        charCode: "",
        code: "",
        key: "",
        keyCode: "",
        which: "",

        location: "",
      },
    };
  },
})
export default class Comp extends Vue {
  keyObj: any = {};

  calcLoc(v: any) {
    if (!cacheEvent) return "";
    switch (v) {
      case cacheEvent.DOM_KEY_LOCATION_LEFT:
        return v + ":DOM_KEY_LOCATION_LEFT";
      case cacheEvent.DOM_KEY_LOCATION_RIGHT:
        return v + ":DOM_KEY_LOCATION_RIGHT";
      case cacheEvent.DOM_KEY_LOCATION_NUMPAD:
        return v + ":DOM_KEY_LOCATION_NUMPAD";
      case cacheEvent.DOM_KEY_LOCATION_STANDARD:
        return v + ":DOM_KEY_LOCATION_STANDARD";
    }
    return "None";
  }

  mounted() {
    cacheEvent = null;
  }
  onKeyPress(e: any) {
    console.log("xx");
    cacheEvent = e;
    e.preventDefault();
    e.stopPropagation();

    for (var i in e) {
      var v = e[i],
        x = typeof v;
      if (x == "object" || x == "undefined") continue;

      //if (typeof this.keyObj[i] == "undefined") continue;
      this.keyObj[i] = v;
    }
  }
}
