import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ToolPage from '@/views/pages/ToolPage.vue'
import tools from './tools'

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue') },
  { path: '/tool', name: 'tool', component: ToolPage },

  ...tools,
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
