
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
  data() {
    return {
      styleObject: {
        left: "280px",
      },
    };
  },
})
export default class Header extends Vue {
  msg!: string;
  styleObject: any;
  mounted() {
    window["emitter"].on("sider.switch", () => {
      if (this.styleObject.left == "0") {
        this.styleObject.left = "280px";
      } else {
        this.styleObject.left = "0";
      }
    });
  }
}
