
import { Options, Vue } from "vue-class-component";

let crypto: any;

@Options({})
export default class Comp extends Vue {
  v1 = "";
  v2 = "";
  hex = "";
  err = false;
  loading = true;
  errText = "";

  async mounted() {
    const x = await import("crypto-js");
    crypto = x;

    this.loading = false;
  }

  encry() {
    if (this.v1 == "") {
      this.err = true;
      this.errText = "Empty input";
      return;
    }

    this.err = false;
    try {
      var x = crypto.enc.Utf8.parse(this.v1);
      var ret = crypto.enc.Base64.stringify(x);
      this.hex = crypto.enc.Hex.stringify(x);
    } catch (e: any) {
      console.log(e);
      this.errText = e.message;
      this.err = true;
      return;
    }
    this.v2 = ret;
  }
  decry() {
    if (this.v2 == "") {
      this.err = true;
      this.errText = "Empty input";
      return;
    }
    this.err = false;
    try {
      var parsedWordArray = crypto.enc.Base64.parse(this.v2);
      var parsedStr = parsedWordArray.toString(crypto.enc.Utf8);
    } catch (e: any) {
      console.log(e);
      this.errText = e.message;
      this.err = true;
      return;
    }
    this.v1 = parsedStr;
  }
}
