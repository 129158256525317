
import { Options, Vue } from "vue-class-component";
@Options({})
export default class Comp extends Vue {
  text = "";
  enVal = "";
  deVal = "";
  enuVal = "";
  deuVal = "";
  escVal = "";

  mounted() {}
  onTextChange(e: any) {
    // var v = e.currentTarget.value;
    try {
      this.enVal = encodeURIComponent(this.text);
    } catch (e) {
      this.enVal = "-";
    }
    try {
      this.deVal = decodeURIComponent(this.text);
    } catch (e) {
      this.deVal = "-";
    }
    try {
      this.enuVal = encodeURI(this.text);
    } catch (e) {
      this.enuVal = "-";
    }
    try {
      this.deuVal = decodeURI(this.text);
    } catch (e) {
      this.deuVal = "-";
    }
    try {
      this.escVal = escape(this.text);
    } catch (e) {
      this.escVal = "-";
    }
  }
}
