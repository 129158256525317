import KeyboardInfo from '@/views/tools/KeyboardInfo.vue'
import None from '@/views/tools/None.vue'
import Hash from '@/views/tools/Hash.vue'
import JsEncode from '@/views/tools/JsEncode.vue'
import TimeStamp from '@/views/tools/TimeStamp.vue'
import Base64 from '@/views/tools/Base64.vue'
import Color from '@/views/tools/Color.vue'
import ReturnEmpire from '@/views/tools/ReturnEmpire.vue'

const dft = "mdi-square-rounded-badge-outline"
export default [
  {
    component: KeyboardInfo, path: '/tool/keyboard', icon: "mdi-keyboard-variant",
    title: "键盘事件", desc: "显示按键的值和状态"
  },
  {
    component: TimeStamp, path: '/tool/time', icon: "mdi-av-timer",
    title: "时间戳", desc: "各种时间转换"
  },
  {
    component: JsEncode, path: '/tool/jsencode', icon: "mdi-swap-horizontal",
    title: "JSencode函数转换", desc: "Javascript各种转码函数decode/encode/URIComponent"
  },
  {
    component: Hash, path: '/tool/hash', icon: "mdi-pound",
    title: "Hash计算", desc: "支持 md5/SHA1/SHA256"
  },
  {
    component: Color, path: '/tool/color', icon: "mdi-palette",
    title: "调色板", desc: "框架内置调色板"
  },
  {
    component: Base64, path: '/tool/base64', icon: "mdi-lock-outline",
    title: "Base64加解密", desc: "四位编码转三位编码"
  },
  {
    component: ReturnEmpire, path: '/tool/dg', icon: "mdi-gamepad-square-outline",
    title: "重返帝国", desc: "攻城时间估算"
  },
]