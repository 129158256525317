
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Comp extends Vue {
  maxLife = 10000; //最大生命
  castleLife = 100; // 城墙耐久
  attack1 = 1000;
  attack2 = 0;
  attack3 = 0;

  async mounted() {}

  count() {
    let a1 = parseInt(this.attack1 + "", 10);
    let a2 = parseInt(this.attack2 + "", 10);
    if (isNaN(a1)) {
      a1 = 0;
    }
    if (isNaN(a2)) {
      a2 = 0;
    }
    let totalAttack = a1 + a2 + this.attack3;
    if (totalAttack <= 0) {
      return "-";
    }
    const m =
      Math.round(((this.castleLife * 10000) / totalAttack / 60) * 100) / 100;
    return m + "分钟";
  }
}
