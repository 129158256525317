import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    "model-value": _ctx.show,
    contained: "",
    class: "align-center justify-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_progress_circular, {
        indeterminate: "",
        color: "primary"
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}