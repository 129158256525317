import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mitt from "mitt"

import "vuetify/styles";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import "@/assets/scss/reset.scss";
import "@/assets/scss/main.scss";
import '@mdi/font/css/materialdesignicons.css'
// https://materialdesignicons.com/ 找各种图标

import Loading from "@/components/Loading.vue"

window.emitter = mitt() //全局事件

const app = createApp(App)

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
})
app.use(vuetify)
app.use(router)
app.component("w-loading", Loading)
app.mount('#app')
