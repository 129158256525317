
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Comp extends Vue {
  year = 0;
  month = 0;
  date = 0;
  hour = 0;
  minute = 0;
  second = 0;

  unix = 0;
  auto = true;

  mounted() {
    this.makeNow();
    this.convertTime();
  }
  setDate(d: Date) {
    this.year = d.getFullYear();
    this.month = d.getMonth() + 1;
    this.date = d.getDate();
    this.hour = d.getHours();
    this.minute = d.getMinutes();
    this.second = d.getSeconds();
  }

  makeNow() {
    this.setDate(new Date());
  }
  onSwitchJudge() {}

  // 输入unix time
  convertUnix() {
    let t = this.unix;
    if (this.auto && this.unix > 1000000000000) {
      t = t / 1000;
    } else {
      // t = t * 1000;
    }
    const d = new Date();
    d.setTime(t * 1000);
    this.setDate(d);
  }
  convertTime() {
    const d = new Date();
    d.setFullYear(this.year);
    d.setMonth(this.month - 1);
    d.setDate(this.date);
    d.setHours(this.hour);
    d.setMinutes(this.minute);
    d.setSeconds(this.second);
    console.log("unix", d);
    this.unix = Math.round(d.getTime() / 1000);
  }
}
