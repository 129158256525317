
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Header extends Vue {
  async mounted() {}
  onClickMore() {
    window["emitter"].emit("sider.switch");
  }
}
