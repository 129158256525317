
/**
 * https://www.npmjs.com/package/crypto-js
 */
import { Options, Vue } from "vue-class-component";

let crypto: any = null;

@Options({})
export default class Comp extends Vue {
  text = "";
  md5 = "";
  sha1 = "";
  sha256 = "";
  checkClear = true;
  processVal = 0;
  loading = true;

  async mounted() {
    crypto = await import(/* webpackChunkName: "crypto" */ "crypto-js");

    this.loading = false;
  }
  onClickCalc() {
    console.log("check?", this.checkClear);
    if (this.checkClear === true) {
      let tmp = this.text.replace(/^\s+/g, "");
      this.text = tmp.replace(/\s+$/g, "");
    }

    this.md5 = crypto.MD5(this.text).toString();
    this.sha1 = crypto.SHA1(this.text).toString();
    this.sha256 = crypto.SHA256(this.text).toString();
  }

  loadFile(e: any) {
    this.processVal = 0;

    let blobSlice = File.prototype.slice;
    let file = e.target.files[0];
    // var chunkSize = 2097152 // read in chunks of 2MB
    let chunkSize = 2097152;
    var chunks = Math.ceil(file.size / chunkSize),
      currentChunk = 0;
    let seed: any = {
      MD5: {},
      SHA1: {},
      SHA256: {},
    };
    for (let x in seed) {
      seed[x].obj = crypto.algo[x].create();
    }
    const me = this;

    /**
     * https://www.cnblogs.com/June2005/p/11429442.html
     *
     * @param ab ArrayBuffer
     */
    function arrayBufferToWordArray(ab: any) {
      var i8a = new Uint8Array(ab);
      var a = [];
      for (var i = 0; i < i8a.length; i += 4) {
        a.push(
          (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]
        );
      }
      return crypto.lib.WordArray.create(a, i8a.length);
    }

    function frOnload(e: any) {
      // log.innerHTML+="\nread chunk number "+parseInt(currentChunk+1)+" of "+chunks;
      me.processVal = Math.round(((currentChunk + 1) / chunks) * 1000) / 10;
      // spark.append(e.target.result); // append array buffer
      // console.log(e)
      let wa = arrayBufferToWordArray(e.target.result);
      for (let x in seed) {
        seed[x].obj.update(wa);
      }

      currentChunk++;
      if (currentChunk < chunks) loadNext();
      else {
        //done
        // console.log('ret',seed.finalize().toString())
        me.processVal = 100;
        for (let x in seed) {
          let val = seed[x].obj.finalize().toString();
          // setValues["set_" + x.toLowerCase()](val);
          switch (x) {
            case "MD5":
              me.md5 = val;
              break;
            case "SHA1":
              me.sha1 = val;
              break;
            case "SHA256":
              me.sha256 = val;
              break;
          }
        }
      }
    }

    function frOnerror() {
      //error
    }

    function loadNext() {
      var fileReader = new FileReader();
      fileReader.onload = frOnload;
      fileReader.onerror = frOnerror;
      var start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  }
}
