<template>
  <Header />
  <Sider />
  <Main />
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Sider from "@/views/layouts/Sider.vue";
import Main from "@/views/layouts/Main.vue";

export default {
  name: "App",
  components: {
    Header,
    Sider,
    Main,
  },
};
</script>
<style lang="scss">
// @use "@/assets/scss/reset.scss" as *;
// @use "@/assets/scss/main.scss" as *;
</style>
