
import { Options, Vue } from "vue-class-component";

// 需要使用 model-value，防点击消失

@Options({
  props: {
    show: false,
  },
})
export default class Comp extends Vue {
  dom = "body";
  show!: boolean; // 属性不要赋值

  mounted() {
    console.log("load ", this.show);
  }
}
